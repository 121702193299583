import { Controller, FieldValues } from 'react-hook-form';
import { ICheckboxField } from './types';
import clsx from 'clsx';

export const CheckboxField = <T extends FieldValues>({
  name,
  label,
  placeholder,
  disabled,
  testId,
  control,
  triggerRevalidation,
  darkMode = false,
  inputClassName,
}: ICheckboxField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <div data-testid={testId}>
            <label className="flex flex-row gap-xs">
              <input
                value={value}
                onChange={(e) => {
                  onChange(e);

                  if (triggerRevalidation) {
                    triggerRevalidation();
                  }
                }}
                disabled={disabled}
                placeholder={placeholder}
                onBlur={onBlur}
                checked={value}
                data-testid={`${testId}-input`}
                className={clsx(
                  `
                    w-xl h-xl mt-[4px]
                    text-purple-8
                    rounded-md
                    cursor-pointer
                    border-[1px] border-black/50 shadow-sm 
                    outline-none outline-0
                    focus:ring 
                    focus:ring-offset-0 
                    focus:ring-0
                    disabled:text-black-5
                    ${error && '!border-red-error'} 
                  `,
                  {
                    [`${inputClassName}`]: inputClassName,
                  },
                )}
                type="checkbox"
              />
              <p className={`text-sm ${darkMode ? 'text-white' : 'text-black-10'} text-justify cursor-pointer`}>
                {label}
              </p>
            </label>
            <div className="w-full min-h-[24px] flex items-center">
              {error && (
                <p
                  data-testid={`${testId}-error-message`}
                  className="w-full text-sm text-red-error text-right"
                >
                  {error.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
